import { EnviromentZone, IHowdenEnvironment, LogLevel } from '@howdeniberia/core-front';
import { howdenGroupAzureB2cDevConfig } from './microsoft-b2c-howdengroup-dev';

export const environment = {
  zone: EnviromentZone.PREHOWDENGROUPB2C,
  appIdSecurity: 0,
  emailError: 'desarrollo@howdeniberia.onmicrosoft.com',
  logLevel: LogLevel.VERBOSE,
  openid: howdenGroupAzureB2cDevConfig
} as IHowdenEnvironment;
